@font-face {
  font-family: 'Union';
  src: url('./js/theme/fonts/Union Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Union';
  font-weight: bold;
  src: url('./js/theme/fonts/Union Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Union';
  font-style: italic;
  src: url('./js/theme/fonts/Union Regular Italic.otf') format('opentype');
}

@font-face {
  font-family: 'Union';
  font-style: italic;
  font-weight: bold;
  src: url('./js/theme/fonts/Union Bold Italic.otf') format('opentype');
}

body {
  margin: 0;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: scroll; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.atcb-initialized {
  display: inline !important;
}

.atcb-button-wrapper {
  transform: scale(0.65);
}

.atcb-list-item {
  transform: scale(0.93);
  margin-top: -5.7px;
  margin-left: 0em;
}

.atcb-list {
  margin-left: -0.2em;
}

.atcb-button{
  background-color: #0F214F !important;
  color: #EBF7F7  !important;
}

.atcb-button:hover {
  background-color: #0F214F  !important;
  color: #EBF7F7 !important;
}

.atcb-active {
  background-color: #0F214F  !important;
  color: #EBF7F7 !important;
}

.atcb-button > .atcb-icon {
  display: none !important;
}

.atcb-button > .atcb-text {
  text-align: center;
  width: -webkit-fill-available;
}

.atcb-dropdown {
  width: auto !important;
}

@media screen and (max-width: 965px) {
  .atcb-initialized {
    display: block !important;
  }
  .atcb-button-wrapper {
    margin-left: -3em;
  }
}

.Toastify__toast-container {
  top: 8.5rem !important;
  max-width: 30rem !important;
  width: fit-content !important;
}

.Toastify__toast-container--top-right {
  right: 0em;
}

div[class*="multiValue"] {
  background-color: lightcyan;
  padding: 2px 4px;
  border-radius: 11px;
}
